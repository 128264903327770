import {Tabs} from "../components";


const AboutUs = () => {
    return (
        <section className="lg:h-screen my-20 flex flex-col justify-center items-center">
            <div className={`w-[90%] lg:w-[85%] mx-auto`}>
                <div className={`text-center`}>
                    <h1 className={`titleHeader text-balance`}>Discover What Sets Us Apart</h1>
                    <p className={`titleText text-wrap`}>No matter who you are, we have a place for you. </p>
                </div>
                <div>
                    <Tabs />
                </div>
            </div>
        </section>
    )
}

export default AboutUs;