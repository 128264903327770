import React from "react";
import { IoCheckmarkCircle } from "react-icons/io5";

const Success = () => {
    return (
        <section className="w-[90%] lg:w-[65%] mx-auto relative flex flex-col items-center justify-center h-[700px]">
            <div className="w-full lg:w-1/2 mx-auto absolute flex flex-col">
                <div className="flex flex-col gap-4 bg-white p-8 lg:p-16 rounded-3xl border drop-shadow-md">
                    <div className="flex justify-center">
                        <IoCheckmarkCircle
                            size={120}
                            color="#008CFE"
                            className="bg-blue-200 rounded-full p-3"
                        />
                    </div>
                    <div className="flex flex-col gap-4 text-center justify-center mb-4">
                        <h1 className="text-lg">Successfully Subscribed!</h1>
                        <p>
                            You will receive an email notification when the app is launched.
                        </p>
                    </div>
                    <div className="relative text-center space-y-10">
                        <a
                            href="/"
                            className="inline-flex gap-2 z-50 items-center bg-primary text-white px-10 py-4 rounded hover:bg-blue-700 transition relative" // Set relative positioning to ensure its on top
                        >
                            Back to home
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Success;
