import {AboutUs, Download, HeroSection, Newsletter} from "../ui";

const Home = () => {
    return (
        <div>
            <HeroSection />
            <AboutUs />
            <Download />
        </div>
    )
}

export default Home;