import { useState } from "react";
import { Tab1, Tab2, Tab3, Tab4 } from "../ui/Tabs";

const Tabs = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [fade, setFade] = useState(false);

    const handleTabClick = (tabNumber) => {
        if (tabNumber !== activeTab) {
            setFade(true);
            setTimeout(() => {
                setFade(false);
                setActiveTab(tabNumber);
            }, 300); // Match this duration to your CSS transition
        }
    };

    return (
        <div className="w-full flex justify-center mt-2">
            <div className="w-full rounded-lg lg:pt-8">
                <div className="flex flex-wrap justify-center gap-3">
                    {["Individuals", "Business Owners", "Shoppers", "Event hosts"].map((label, index) => {
                        const tabNumber = index + 1;
                        return (
                            <button
                                key={tabNumber}
                                onClick={() => handleTabClick(tabNumber)}
                                className={`py-2 px-8 lg:py-2 lg:px-6 border rounded-full ${
                                    activeTab === tabNumber
                                        ? "bg-gradient-to-r from-gradient-variant2 to-gradient-variant1 text-white"
                                        : ""
                                }`}
                            >
                                {label}
                            </button>
                        );
                    })}
                </div>
                <div
                    className={`mt-10 lg:mt-20 p-1 transition-opacity duration-300 ${
                        fade ? "opacity-0" : "opacity-100"
                    }`}
                >
                    {activeTab === 1 && <Tab1 />}
                    {activeTab === 2 && <Tab2 />}
                    {activeTab === 3 && <Tab3 />}
                    {activeTab === 4 && <Tab4 />}
                </div>
            </div>
        </div>
    );
};

export default Tabs;
