import { useState } from "react";

const Subscribe = () => {
    const [formStatus, setFormStatus] = useState(""); // For status or error messages
    const [loading, setLoading] = useState(false); // Loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFormStatus(""); // Reset status on new submission

        const form = e.target;
        const data = new FormData(form); // Automatically captures form data

        try {
            const response = await fetch("http://localhost:5001/waitList", {
                method: "POST",
                body: data, // Sending FormData directly
            });

            if (!response.ok) {
                // Capture server error
                const errorData = await response.json();
                throw new Error(errorData.message || "Unknown error occurred");
            }

            // On success
            form.reset();
            setFormStatus("Subscription successful! Redirecting...");
            setTimeout(() => {
                window.location.href = "/"; // Redirect after success
            }, 2000); // Wait briefly to show success message
        } catch (error) {
            console.error("Fetch error:", error);
            setFormStatus(`Error: ${error.message}`); // Display error message
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="space-y-4">
            <form
                onSubmit={handleSubmit}
                className="w-full flex items-center justify-between border rounded-lg p-4 box-border"
            >
                <input
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    required
                    className="flex-1 input border p-2 rounded-lg mr-4"
                />
                <button
                    type="submit"
                    disabled={loading}
                    className={`bg-primary text-white px-6 py-4 rounded-lg transition hover:bg-blue-700 ${
                        loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    {loading ? "Subscribing..." : "Submit"}
                </button>
            </form>
            {formStatus && (
                <p
                    className={`mt-4 text-center ${
                        formStatus.startsWith("Error")
                            ? "text-red-600"
                            : "text-green-600"
                    }`}
                >
                    {formStatus}
                </p>
            )}
        </div>
    );
};

export default Subscribe;
