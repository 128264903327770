import {Form} from "../components";
import { BsInstagram, BsLinkedin, BsTwitterX } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Contact = () => {
  return (
    <div className={`w-[90%] lg:w-[80%] mx-auto my-20`}>
        <div className={`lg:grid grid-cols-3 gap-20`}>
            <div className={`space-y-10`}>
                <h1 className={`text-[40px] font-bold text-primary`}>Contact <span className={`text-black`}>Us</span></h1>
                <p className={`text-[20px] font-bold`}>We'd really like to hear from you,</p>
                <p className={`text-[16px] text-[#645E5E]`}>Fill out the contact form or message us using any of the mediums below</p>
                <div className={`space-y-6`}>
                    <a
                        href="https://www.instagram.com/fasfill/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`flex items-center gap-4`}
                    >
                        {/* Icon with Red Background */}
                        <div className="w-[40px] h-[40px] bg-primary flex items-center justify-center rounded-full">
                            <HiOutlineEnvelope className={`w-[30px] h-[30px] text-white`}/>
                        </div>
                        {/* Text */}
                        <p className={`text-[16px] font-bold`}>info@fasfill.com</p>
                    </a>
                    <a
                        href="https://www.instagram.com/fasfill/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`flex items-center gap-4`}
                    >
                        {/* Icon with Red Background */}
                        <div className="w-[40px] h-[40px] bg-primary flex items-center justify-center rounded-full">
                            <AiFillInstagram className={`w-[30px] h-[30px] text-white`}/>
                        </div>
                        {/* Text */}
                        <p className={`text-[16px] font-bold`}>_fasfill</p>
                    </a>
                    <a
                        href="https://www.instagram.com/fasfill/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`flex items-center gap-4`}
                    >
                        {/* Icon with Red Background */}
                        <div className="w-[40px] h-[40px] bg-primary flex items-center justify-center rounded-full">
                            <FaLinkedinIn className={`w-[25px] h-[25px] text-white`}/>
                        </div>
                        {/* Text */}
                        <p className={`text-[16px] font-bold`}>fasfill</p>
                    </a>
                    <a
                        href="https://www.instagram.com/fasfill/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`flex items-center gap-4`}
                    >
                        {/* Icon with Red Background */}
                        <div className="w-[40px] h-[40px] bg-primary flex items-center justify-center rounded-full">
                            <FaXTwitter className={`w-[20px] h-[20px] text-white`}/>
                        </div>
                        {/* Text */}
                        <p className={`text-[16px] font-bold`}>_fasfill</p>
                    </a>
                </div>
            </div>
            <div className={`lg:col-span-2`}>
                <Form/>
            </div>
        </div>
    </div>
  );
};

export default Contact;