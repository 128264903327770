import {FooterItems} from "../constants";

const Footer = () => {
    return (
        <footer className={`flex flex-col justify-between items-center py-10 w-[90%] lg:w-[85%] mx-auto space-y-10`}>
            <div className={`flex flex-wrap justify-center gap-4 lg:gap-10`}>
                {
                    FooterItems.map((item, index) => {
                        return (
                                <li key={index} className={`flex`}>
                                    <a href={item.url} rel="noopener noreferrer flex">
                                        {item.value}
                                    </a>
                                </li>
                        )
                    })
                }
            </div>
            <p className={`text-center text-[#1A1A1A]/50`}>© Copyright 2025 Fasfill. All rights reserved.</p>
        </footer>
    )
}

export default Footer;