import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { faqs } from "../constants";

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null); // For top-level questions

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="flex flex-col items-center justify-center w-full py-10">
            <div className="w-full max-w-3xl px-4">
                <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">FAQ</h2>
                <div className="space-y-4">
                    {faqs.map((item, index) => (
                        <div
                            key={index}
                            className={`bg-gray-50 rounded-lg shadow-md p-4 transition-all ${
                                openIndex === index ? "ring-2 ring-blue-400" : ""
                            }`}
                        >
                            <div
                                className="flex items-center cursor-pointer"
                                onClick={() => toggleFAQ(index)}
                            >
                                <div
                                    className={`p-2 rounded-full transition-transform ${
                                        openIndex === index
                                            ? "bg-blue-500 text-white rotate-180"
                                            : "bg-gray-200 text-gray-600"
                                    }`}
                                >
                                    <FiChevronDown className="w-5 h-5" />
                                </div>
                                <h3 className="ml-4 text-lg font-medium text-gray-800">
                                    Question {index + 1}: {item.question}
                                </h3>
                            </div>
                            {openIndex === index && (
                                <div className="mt-2 px-6 text-gray-700 text-sm">
                                    <p className={`ml-8 text-lg`}>Answer:</p>
                                    {item.answer.text1 && (
                                        <p className="pl-8 mb-4">{item.answer.text1}</p>
                                    )}
                                    {item.answer.list && (
                                        <ul className="list-decimal pl-14 mt-3 space-y-2">
                                            {item.answer.list.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <p>{subItem.textList1}</p>
                                                    {subItem.subList && (
                                                        <ul className="list-disc pl-5 mt-2 space-y-1">
                                                            {subItem.subList.map((nestedItem, nestedIndex) => (
                                                                <li key={nestedIndex} className="text-gray-600">
                                                                    {nestedItem}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    <p className={`ml-10 pt-2`}>
                                        {item.answer.text2}
                                    </p>
                                    {item.answer.list2 && (
                                        <ul className="list-decimal pl-14 mt-3 space-y-2">
                                            {item.answer.list2.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <p>{subItem.textList2}</p>
                                                    {subItem.subList && (
                                                        <ul className="list-disc pl-5 mt-2 space-y-1">
                                                            {subItem.subList.map((nestedItem, nestedIndex) => (
                                                                <li key={nestedIndex} className="text-gray-600">
                                                                    {nestedItem}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
