export const NavLinks = [
    {
        id: 1,
        value: 'Home',
        url: "/"
    },
    {
        id: 2,
        value: 'Help Center',
        url: "/Help Center"
    },
]

export const FooterItems = [
    {
        id: 1,
        value: 'Gridhall Inc.',
        url: "https://gridhall.com"
    },
    {
        id: 2,
        value: 'Help Center',
        url: "/Help Center"
    },
    {
        id: 3,
        value: 'Contact Us',
        url: "/Contact us"
    },
    {
        id: 4,
        value: 'Report an Issue',
        url: "/Report an Issue"
    },
]

export const faqs = [
    {
        question: "How do I find my contacts?",
        answer: {
            text1: "To find your contacts on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfill account.",
                },
                {
                    textList1: "Use the search bar to type the name, email, or username of the person you’re looking for.",
                },
                {
                    textList1: "If you have synced your device contacts, Fasfill will show a list of people who are already on the platform.",
                },
            ],
            text2: "Great! Here’s the next one",
        },
    },
    {
        question: "How can I add a business?",
        answer: {
            text1: "To add your business on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfil account.",
                },
                {
                    textList1: "Go to the Business section from the main menu.",
                },
                {
                    textList1: "Click on \"Add Business\" and fill out the required details, including:",
                    subList: [
                        "Business name",
                        "Description",
                        "Category",
                        "Contact information",
                        "Location",
                    ],
                },
                {
                    textList1: "Upload your business logo and any relevant images."
                },
                {
                    textList1: "Review your information and click \"Submit\".",
                },
            ],
            text2: "Your business will be live on Fasfill after a verification process is carried out by our team!",
        },
    },

    {
        question: "How do I promote my business?",
        answer: {
            text1: "To promote your business on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfill account and navigate to the Business section.",
                },
                {
                    textList1: "Select the business you want to promote.",
                },
                {
                    textList1: "Click on \"Promote\" and choose your preferred ad type:",
                    subList: [
                        "Go Live",
                        "Create Campaign",
                    ],
                },
                {
                    textList1: "If you select Create Campaign:",
                    subList: [
                        "Click on \"Create New Campaign\".",
                        "Set your budget, target audience, campaign duration, and upload campaign banners.",
                        "Review and publish your ad details.",
                        "Once approved, your promotion will appear in users’ feeds and relevant searches.",
                    ],
                },
                {
                    textList1: "If you select Go Live:",
                    subList: [
                        "Enter your campaign details, including budget, title, privacy settings, and duration.",
                        "Proceed to make payment.",
                        "Your campaign will go live instantly on your business page.",
                    ]
                }
            ],
        },
    },
    {
        question: " How do I change my password?",
        answer: {
            text1: "To change your password on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfill account.",
                },
                {
                    textList1: "Go to the Me section in the main menu.",
                },
                {
                    textList1: "Select Settings, then click on Password and Security.",
                },
                {
                    textList1: "Click Change Password.",
                },
                {
                    textList1: "Enter the email address associated with your account and verify your identity by entering" +
                        " the code sent to your email.",
                },
                {
                    textList1: "Type in your new password and confirm it.",
                },
                {
                    textList1: "Click Update Password to update your credentials.",
                }
            ],
            text2: "Tip: Use a strong password with a mix of letters, numbers, and special characters to keep your account secure."
        },
    },
    {
        question: "How do I edit my profile details?",
        answer: {
            text1: "To edit your profile details on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfill account.",
                },
                {
                    textList1: "Go to the Me section in the main menu.",
                },
                {
                    textList1: "Select My Profile, then click the hamburger menu (three lines) at the top right of the screen.",
                },
                {
                    textList1: "Click on Edit Profile.",
                },
                {
                    textList1: "On the next screen, you can update all your profile details.",
                },
                {
                    textList1: "Make the necessary changes and click Save Changes to update your profile."
                },
            ],
        },
    },
    {
        question: "How do I turn on/off device permissions?",
        answer: {
            text1: " To manage device permissions on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfill account.",
                },
                {
                    textList1: "Go to the Me section in the main menu.",
                },
                {
                    textList1: "Select Settings and click on Device Permissions.",
                },
                {
                    textList1: "Toggle on/off the permissions you want to grant Fasfill, such as:",
                    subList: [
                        "Camera",
                        "Contacts",
                        "Location",
                        "Microphone",
                        "Gallery",
                    ],
                },
            ],
            text2: "You can enable or disable these permissions anytime to customize your experience."
        },
    },

    {
        question: "How do I create a circle?",
        answer: {
            text1: "To create a circle on Fasfill:",
            list: [
                {
                    textList1: "Log in to your Fasfill account.",
                },
                {
                    textList1: "Navigate to the Circles section in the main menu.",
                },
                {
                    textList1: "Click on Create Circle.",
                },
                {
                    textList1: "Enter a name and description for your circle.",
                },
                {
                    textList1: "Set your circle's privacy preferences (e.g., Public, Private, or Invite-Only).",
                },
                {
                    textList1: "Add an image or logo for your circle if desired.",
                },
                {
                    textList1: "Click Create to finalize your circle.",
                },
            ],
            text2: "Once created, you can start inviting people and building your community!",
        }
    },

    {
        question: "How do I add or remove people from my circle?",
        answer: {
            text1: "To Add People:",
            list: [
                {
                    textList1: "Log in to your Fasfill account and go to the Circles section.",
                },
                {
                    textList1: "Select the circle you want to manage.",
                },
                {
                    textList1: "Click on Add Members or Invite.",
                },
                {
                    textList1: "Search for the person by username or email, or select from your existing contacts.",
                },
                {
                    textList1: "Send the invite, and once accepted, they’ll join your circle.",
                }
            ],
            text2: "To Remove People:",
            list2: [
                {
                    textList2: "Go to the circle where the person is a member.",
                },
                {
                    textList2: "Click on Members to view the list of current members.",
                },
                {
                    textList2: "Find the person you want to remove, and click on the Remove from Circle.",
                },
                {
                    textList2: "Confirm the action to complete the removal.",
                },
            ],
        },


    }
];
