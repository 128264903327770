import React, { useState } from 'react';
import { NavLinks } from '../constants';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Logo } from '../assets';
import { useLocation } from 'react-router-dom';
import Popup from './Popup';
import { Subscribe } from './index';

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Fixed initial state to `false`.

    return (
        <header className="bg-primary-light sticky top-0 z-50">
            <nav className="w-[90%] lg:w-[85%] mx-auto flex items-center justify-between py-6" aria-label="Global">
                {/* Logo */}
                <a href="/" className="flex lg:flex-1 gap-6 -m-1.5 p-1.5">
                    <img src={Logo} alt="logo" className="md:w-[140px] w-[120px]" />
                </a>

                {/* Desktop Navigation */}
                <div className="hidden lg:flex lg:gap-x-12">
                    {NavLinks.map(({ value, url }) => (
                        <a
                            key={value}
                            href={url}
                            className={`text-md font-medium leading-6 ${
                                location.pathname === url ? 'text-primary' : 'text-black'
                            }`}
                        >
                            {value}
                        </a>
                    ))}
                </div>

                {/* Call to Action */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-end z-20">
                    <button
                        className="py-1 px-2 md:py-3 md:px-8 bg-primary text-lg text-white rounded"
                        onClick={() => setIsPopupOpen(true)} // Open Popup
                    >
                        Get started for free
                    </button>
                    <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                        <Subscribe />
                    </Popup>
                </div>

                {/* Mobile Menu Button */}
                <button
                    type="button"
                    className="lg:hidden -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </nav>

            {/* Mobile Navigation */}
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full bg-white px-6 py-6 sm:max-w-sm">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img src={Logo} alt="logo" className="md:w-[300px] w-[120px]" />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-black"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="mt-20 flow-root">
                        <div className="">
                            {/* Mobile Nav Links */}
                            <div className="py-6">
                                {NavLinks.map(({value, url}) => (
                                    <a
                                        key={value}
                                        href={url}
                                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 ${
                                            location.pathname === url ? 'text-primary' : 'text-black'
                                        } hover:text-primary`}
                                    >
                                        {value}
                                    </a>
                                ))}
                            </div>

                            {/* Mobile Call to Action */}
                            <div className="lg:hidden ">
                                <button
                                    className="py-2 px-4 bg-primary text-lg text-white rounded"
                                    onClick={() => setIsPopupOpen(true)} // Open Popup
                                >
                                    Get started for free
                                </button>
                                <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                                    <Subscribe/>
                                </Popup>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};

export default Navbar;
