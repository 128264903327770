import { useState, useRef } from "react";
import { GrAttachment } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai"; // Import the delete icon

const Form = () => {
    const [formStatus, setFormStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState(""); // State to hold the file name
    const fileInputRef = useRef(null); // Ref to access the file input

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileName(file.name); // Set the file name to the selected file's name
        }
    };

    const handleRemoveFile = () => {
        setFileName(""); // Clear file name
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear file input
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const form = e.target;
        const data = new FormData(form); // Attach all form data including files

        try {
            // const response = await fetch("https://gridhall-com.onrender.com/contactForm", {
            //     method: "POST",
            //     body: data, // send FormData directly
            // });
            const response = await fetch("http://localhost:5001/contactForm", {
                method: "POST",
                body: data, // send FormData directly
            });

            if (!response.ok) {
                const errorData = await response.json();
                setFormStatus(
                    `An error occurred! : ${
                        errorData.message || "Unknown error occurred."
                    }`
                );
            } else {
                form.reset();
                setFileName(""); // Clear file name after form submission
                window.location.href = "/Success";
            }
        } catch (error) {
            console.error("Fetch error:", error);
            setFormStatus(`An error occurred!: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    return (
        <section>
            <form
                onSubmit={handleSubmit}
                className="bg-white p-6 lg:p-20 shadow-md rounded-lg flex flex-col lg:gap-6"
            >
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        className="border border-gray-300 rounded-lg p-3"
                        required
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className="border border-gray-300 rounded-lg p-3"
                        required
                    />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <input
                        type="tel"
                        name="tel"
                        placeholder="Phone number (optional)"
                        className="border border-gray-300 rounded-lg p-3 mt-4 w-full"
                    />
                    <input
                        type="text"
                        name="subject"
                        placeholder="Subject (optional)"
                        className="border border-gray-300 rounded-lg p-3 mt-4 w-full"
                    />
                </div>
                <textarea
                    name="message"
                    placeholder="Message"
                    className="border border-gray-300 rounded-lg p-3 mt-4 w-full"
                    rows="5"
                    required
                ></textarea>
                <div className="lg:flex items-center justify-between">
                    <div className="flex flex-col mt-4">
                        <label className="flex items-center">
                            <input
                                type="file"
                                name="attachment"
                                className="hidden"
                                ref={fileInputRef} // Attach ref to the file input
                                onChange={handleFileChange} // Attach onChange handler
                            />
                            <span className="cursor-pointer flex items-center gap-2">
                    <GrAttachment/> Attach document
                  </span>
                        </label>
                        {fileName && (
                            <div className="flex items-center mt-2 text-gray-600">
                                <p className="text-sm">Attached: {fileName}</p>
                                <button
                                    type="button"
                                    onClick={handleRemoveFile}
                                    className="ml-2 text-red-500"
                                    aria-label="Remove file"
                                >
                                    <AiOutlineClose/>
                                </button>
                            </div>
                        )}
                    </div>
                    <button
                        type="submit"
                        className={`bg-primary text-white px-6 py-3 rounded-lg transition hover:bg-blue-700 mt-4 ${
                            loading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? "Sending..." : "Send Message"}
                    </button>
                </div>
                {formStatus && <p className="mt-4 text-center">{formStatus}</p>}
            </form>
        </section>
    );
};

export default Form;
