import {FAQ, HelpCenterHero} from "../ui";

const HelpCenter = () => {
    return (
        <div>
            <HelpCenterHero />
            <FAQ />
        </div>
    )
}

export default HelpCenter