import {HeroBg_2} from "../assets";


const HelpCenterHero = () => {
  return (
      <div>
          <div className={`w-full lg:h-[369px] flex justify-center items-center py-20 bg-no-repeat bg-cover relative`} style={{ backgroundImage: `url(${HeroBg_2})` }}>
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center" />
              <div className={`z-20`}>
                  <h1 className={`text-center text-white text-[40px] font-bold`}>Help Center</h1>
                  <p className={`text-white text-[18px] text-center`}>Got questions? Ask us anything</p>
              </div>
          </div>
      </div>
  )
}

export default HelpCenterHero;