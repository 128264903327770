import {AppStoreDark, GooglePlayDark, HeroBg} from "../assets";
import "../index.css"
import { motion } from "framer-motion";
import { slideIn, staggerContainer, textVariant } from '../utils/motion';
import React, {useState} from "react";
import {Subscribe, Popup} from "../components";



const HeroSection = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Fixed initial state to `false`.

    return (
        <motion.div
            variants={staggerContainer}
            // initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className="w-full bg-primary-light  lg:h-[800px] py-20 lg:pb-0 lg:pt-40">
            <div className={`w-[90%] lg:w-[85%] mx-auto flex flex-col-reverse lg:grid grid-cols-3 justify-center items-start`}>
                <div className={`space-y-2`}>
                    <div className={`my-6 space-y-3`}>
                        <motion.h1 variants={textVariant(0.5)} className="heroText">FasFill: The Pulse <br/> of Your City</motion.h1>
                        <p className={`text-center lg:text-left`}>Stay well connected and informed with the Fasfill app, and gain access to our services from
                            anywhere with our Android App and IOS App!</p>
                    </div>
                    <div className={`flex justify-center lg:justify-normal gap-4 cursor-pointer`}>
                        <img src={GooglePlayDark} alt="store_logo" onClick={() => setIsPopupOpen(true)} className="w-[140px] lg:w-[168px] h-auto"/>
                        <img src={AppStoreDark} alt="store_logo" onClick={() => setIsPopupOpen(true)} className="w-[140px] lg:w-[168px] h-auto" />
                        <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                            <Subscribe />
                        </Popup>
                    </div>
                </div>
                <motion.div variants={slideIn('right', 'tween', 0.2, 1)} className={`col-span-2`}>
                    <img src={HeroBg} alt="store_logo" className="w-full h-auto object-cover object-center" />
                </motion.div>
            </div>
        </motion.div>
    )
}

export default HeroSection