import {AppStore, Banner1, Banner2, GooglePlay} from "../assets";
import React, {useState} from "react";
import {Popup, Subscribe} from "../components";

const Download = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Fixed initial state to `false`.

    return (
        <section className="bg-primary h-[400px] lg:h-[600px] flex items-center relative overflow-hidden">
            <div className="w-[90%] lg:w-[80%] mx-auto lg:grid grid-cols-2 gap-32">
                {/* Left Content */}
                <div className="space-y-6 z-10">
                    <h1 className="text-4xl text-white font-medium">Download Our App & Start Exploring</h1>
                    <p className="text-white lg:text-2xl font-normal">
                        Unlock a world of possibilities. Join Fasfill to showcase your business, create and discover
                        local events, and stay connected with verified community updates.
                    </p>
                    <div className="flex gap-4">
                        <img src={GooglePlay} onClick={() => setIsPopupOpen(true)} alt="Download from Google Play Store" className="w-[140px] lg:w-[168px] h-auto cursor-pointer" />
                        <img src={AppStore} onClick={() => setIsPopupOpen(true)} alt="Download from Apple App Store" className="w-[140px] lg:w-[168px] h-auto cursor-pointer" />
                        <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                            <Subscribe />
                        </Popup>
                    </div>
                </div>

                {/* Right Content */}
                <div className="hidden lg:block absolute top-0 right-0">
                    <img src={Banner2} alt="App Banner" className="w-[70%] object-cover float-right" />
                    <img src={Banner1} alt="App Banner" className="absolute top-0 left-0 w-[20%]" />
                </div>
            </div>
        </section>
    );
};

export default Download;
