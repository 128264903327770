import React, { useEffect } from "react";
import {Banner, Tab1_Img1} from "../assets";

const Popup = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        // Toggle the overflow-hidden class on the body when popup state changes
        if (isOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
        // Cleanup class on component unmount
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
            <div className="lg:grid grid-cols-2 items-center gap-10 bg-white p-6 lg:p-20 rounded shadow-lg w-[90%] lg:w-[80%] space-y-6">
                <div className={``}>
                    <img src={Tab1_Img1} alt="Banner" />
                </div>
                <div className={`space-y-4`}>
                    <h1 className={`text-2xl font-bold`}>Join the waitlist</h1>
                    <p>Be the first to know when we launch our app</p>
                    {children}
                    <button
                        onClick={onClose}
                        className="mt-4 px-4 py-2 bg-primary text-white rounded-lg transition hover:bg-blue-700"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
