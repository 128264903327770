import {Routes, Route, BrowserRouter} from "react-router-dom"
import {Footer, Navbar} from "../components";
import {Home, ContactUs, HelpCenter, Success} from "../pages";
import "../index.css"
import {Newsletter} from "../ui";


const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path="/" index element={<Home/>}/>
                <Route path="/Contact Us" element={<ContactUs/>}/>
                <Route path="/Help Center" element={<HelpCenter/>}/>
                <Route path="/Success" element={<Success/>}/>
            </Routes>
            <Newsletter />
            <hr className={`w-[90%] lg:w-[85%] mx-auto`}/>
            <Footer/>
        </BrowserRouter>
    )
}

export default AppRoutes;