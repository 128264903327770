import {Contact, Newsletter} from "../ui";

const ContactUsPage = () => {
    return (
        <div>
            <Contact />
        </div>
    )
}

export default ContactUsPage;