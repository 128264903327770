import {Logo} from "../assets";
import { FiTwitter, FiLinkedin, FiInstagram } from "react-icons/fi"
import {Subscribe} from "../components";

const Newsletter = () => {
    return (
        <section className="mt-32 mb-10">
            <div className={`w-[90%] lg:w-[85%] mx-auto lg:grid grid-cols-3 justify-center items-center`}>
                <div>
                    <a href="/">
                        <img src={Logo} alt="logo" className={`md:w-[140px] w-[120px]`} />
                    </a>
                </div>
                <div className={`space-y-4`}>
                    <p className={`lg:text-center`}>Join our wait list and  be the first to know when Fasfill is launched</p>
                    <Subscribe />
                </div>
                <div className={`flex lg:justify-center mt-10 gap-2`}>
                    <a href="/" className={`bg-primary p-2 text-white rounded-full`}><FiTwitter /></a>
                    <a href="/" className={`bg-primary p-2 text-white rounded-full`}><FiLinkedin /></a>
                    <a href="/" className={`bg-primary p-2 text-white rounded-full`}><FiInstagram /></a>
                </div>
            </div>
        </section>
    )
}

export default Newsletter