import {AppStoreDark, GooglePlayDark, Tab2_Img1, Tab2_Img2, Tab2_Img3} from "../../assets";
import React, {useEffect, useState} from "react";
import {Popup, Subscribe} from "../../components";



const Tab2 = () => {
    const images = [
        Tab2_Img1, Tab2_Img2, Tab2_Img3,
    ];
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Fixed initial state to `false`.


    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className={`flex flex-col-reverse lg:grid grid-cols-2 gap-10`}>
            <div className={``}>
                {/*<img src={Tab1_Img1} alt="img" className={`w-[536px] h-auto box-border float-right`}/>*/}

                <div className="relative w-full max-w-2xl mx-auto overflow-hidden">
                    <div
                        className="flex transition-transform duration-700 ease-in-out object-cover"
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                    >
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className="w-full flex-shrink-0"
                            >
                                <img
                                    src={image}
                                    alt={`Slide ${index + 1}`}
                                    className="w-full h-auto object-cover"
                                />
                            </div>
                        ))}
                    </div>
                    {/* Optional indicators */}
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                        {images.map((_, index) => (
                            <button
                                key={index}
                                className={`w-3 h-3 rounded-full ${
                                    currentIndex === index
                                        ? "bg-blue-500"
                                        : "bg-gray-300"
                                }`}
                                onClick={() => setCurrentIndex(index)}
                            ></button>
                        ))}
                    </div>
                </div>

            </div>
            <div className={`space-y-4 flex flex-col justify-center`}>
                {/* All list items*/}
                <h1 className={`tabTitle`}>Are you a business owner?</h1>

                <div className={`space-y-4`}>
                    <article className="">
                        <div className="flex items-start gap-6">
                            <div
                                className="bg-gradient-to-r from-gradient-variant2 to-gradient-variant1 text-white flex size-8 shrink-0 place-content-center rounded-full"
                                aria-hidden="true"
                            >
                                <div className="flex items-center">
                                    <p>1</p>
                                </div>
                            </div>

                            <div className={`space-y-1`}>
                                <h3 className="text-lg font-medium sm:text-xl">Create a business account
                                </h3>

                                <p className="mt-1 text-sm text-[#1A1A1A]/70">Create a business account with us to unlock exclusive tools and insights that help you grow and manage your business efficiently. Set up in minutes and optimize your operations for success!</p>
                            </div>
                        </div>
                    </article>

                    <article className="">
                        <div className="flex items-start gap-6">
                            <div
                                className="bg-gradient-to-r from-gradient-variant2 to-gradient-variant1 text-white flex size-8 shrink-0 place-content-center rounded-full"
                                aria-hidden="true"
                            >
                                <div className="flex items-center">
                                    <p>2</p>
                                </div>
                            </div>

                            <div className={`space-y-1`}>
                                <h3 className="text-lg font-medium sm:text-xl">Advertise with us
                                </h3>

                                <p className="mt-1 text-sm text-[#1A1A1A]/70">Advertise with us to reach a broader audience and grow your brand with targeted ad placements, connecting you to millions of potential customers.</p>
                            </div>
                        </div>
                    </article>

                    <article className="">
                        <div className="flex items-start gap-6">
                            <div
                                className="bg-gradient-to-r from-gradient-variant2 to-gradient-variant1 text-white flex size-8 shrink-0 place-content-center rounded-full"
                                aria-hidden="true"
                            >
                                <div className="flex items-center">
                                    <p>3</p>
                                </div>
                            </div>

                            <div className={`space-y-1`}>
                                <h3 className="text-lg font-medium sm:text-xl">Manage your staff</h3>

                                <p className="mt-1 text-sm text-[#1A1A1A]/70">We offers a seamless solution for managing your staff efficiently. With our intuitive tools you can easily oversee your staff's bookings and scheduling</p>
                            </div>
                        </div>
                    </article>
                </div>

                <div className={`flex justify-center lg:justify-normal gap-4 ml-14`}>
                    <img src={GooglePlayDark} alt="store_logo" onClick={() => setIsPopupOpen(true)}
                         className="w-[140px] lg:w-[140px] h-auto cursor-pointer"/>
                    <img src={AppStoreDark
                    } alt="store_logo" onClick={() => setIsPopupOpen(true)}
                         className="w-[140px] lg:w-[140px] h-auto cursor-pointer"/>
                    <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                        <Subscribe/>
                    </Popup>
                </div>
            </div>
        </div>
    )
}

export default Tab2;